<template>
  <div>
    <div class="row my-0">
      <div class="col-12">
        <h1 class="mb-4">{{ SHARED.SupportCenterPage.Title }}</h1>
        <p>{{ SHARED.SupportCenterPage.Description }}</p>
      </div>
    </div>

    <recess-divider show-line />

    <div class="row mb-5">
      <div class="col-12">
        <h3 class="mb-2">{{ SHARED.SupportCenterPage.ContactTopic.Title }}</h3>
        <p>
          <span class="u-fw-semi-bold">{{
            SHARED.SupportCenterPage.ContactTopic.EmailText
          }}</span>
          {{ SHARED.SupportCenterPage.ContactTopic.Email }}
        </p>
        <p>
          <span class="u-fw-semi-bold">{{
            SHARED.SupportCenterPage.ContactTopic.PhoneNumberText
          }}</span>
          {{ SHARED.SupportCenterPage.ContactTopic.PhoneNumber }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { SHARED } from "../../constants/EdumsSharedConstants.json";

export default {
  name: "Contacts",
  data() {
    return {
      SHARED,
    };
  },
};
</script>
 